import CheckIcon                                                            from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon
                                                                            from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon                                                             from '@mui/icons-material/Done';
import LinkIcon                                                             from '@mui/icons-material/Link';
import MoreVertIcon                                                         from '@mui/icons-material/MoreVert';
import PendingActionsIcon                                                   from '@mui/icons-material/PendingActions';
import RestartAltIcon                                                       from '@mui/icons-material/RestartAlt';
import RotateLeftIcon                                                       from '@mui/icons-material/RotateLeft';
import SendIcon                                                             from '@mui/icons-material/Send';
import VisibilityIcon                                                       from '@mui/icons-material/Visibility';
import VisibilityOffIcon                                                    from '@mui/icons-material/VisibilityOff';
import WebIcon                                                              from '@mui/icons-material/Web';
import WebAssetOffIcon                                                      from '@mui/icons-material/WebAssetOff';
import { Tooltip }                                                          from '@mui/material';
import Box                                                                  from '@mui/material/Box';
import Button                                                               from '@mui/material/Button';
import IconButton                                                           from '@mui/material/IconButton';
import Link                                                                 from '@mui/material/Link';
import Typography                                                           from '@mui/material/Typography';
import { GridActionsCellItem as MuiGridActionsCellItem }                    from '@mui/x-data-grid'
import { graphql }                                                          from '../data/client';
import { GQL_MUTATION_CREATE_INVITE }                                       from '../data/invite';
import { GQL_MUTATION_RESEND_CONFIRM_EMAIL }                                from '../data/register/mutations';
import { GQL_QUERY_GET_STUDENTS, GQL_QUERY_RESEND_INVITE_EMAIL_BY_STUDENT } from '../data/student';
import { useDialogCtrl }                                                    from '../lib/Form';
import MaterialTable                                                        from '../lib/MaterialTable';
import { DateCell }                                                         from '../lib/MaterialTable/projectColumns';
import { MoreActionMenu }                                                   from '../lib/menuElements';
import useSubmitSnack                                                       from '../lib/snacks';


const programMap = {
  'Single Subject':                              'SS',
  'Multiple Subject':                            'MS',
  'Multiple Subject -- Bilingual Authorization': 'MSBA',
}

const boolParser = (v) => v === 'true' ? true : (v === 'false' ? false : undefined);

const columns = [
  {
    id:          'uciStudentNum',
    header:      'Student #',
    hidden:      true,
    accessorKey: 'uciStudentNum',
  },
  {
    id:          'lastName',
    header:      'Last',
    accessorKey: 'lastName',
  },
  {
    id:          'firstName',
    header:      'First',
    accessorKey: 'firstName',
  },
  {
    id:          'email',
    header:      'Email',
    hidden:      true,
    accessorKey: 'email',
  },
  {
    id:          'phone',
    header:      'Phone',
    hidden:      true,
    accessorKey: 'phone',
  },
  {
    id:          'program',
    header:      'Pgrm',
    size:        50,
    accessorKey: 'program',
    Cell:        ({ cell }) => {
      const v = cell.getValue();

      return v ? (
        <Box width="100%">
          <Tooltip title={v}>
            {programMap[v] ?? v}
          </Tooltip>
        </Box>
      ) : <Box>--</Box>;
    },
  },
  {
    id:          'subject',
    header:      'Subj',
    hidden:      true,
    size:        50,
    accessorKey: 'subject',
  },
  {
    id:            'inviteSeen',
    header:        'Seen',
    size:          30,
    filterVariant: 'checkbox',
    filterParser:  boolParser,
    hidden:        true,
    accessorFn:    ({ inviteStatus }) => inviteStatus?.seen ? <VisibilityIcon color="success" /> : <VisibilityOffIcon
      color="disabled" />,
    Cell:          ({ cell }) => <Box width="100%" textAlign="center">{cell.getValue()}</Box>,
  },
  {
    id:            'inviteVisited',
    header:        'Visited',
    size:          30,
    filterVariant: 'checkbox',
    filterParser:  boolParser,
    hidden:        true,
    accessorFn:    ({ inviteStatus }) => inviteStatus?.visited ? <WebIcon color="success" /> : <WebAssetOffIcon
      color="disabled" />,
    Cell:          ({ cell }) => <Box width="100%" textAlign="center">{cell.getValue()}</Box>,
  },
  {
    id:            'inviteComplete',
    header:        'Complete',
    size:          30,
    filterVariant: 'checkbox',
    filterParser:  boolParser,
    hidden:        true,
    accessorFn:    ({ inviteStatus }) => inviteStatus?.complete ? <DoneIcon color="success" /> : <PendingActionsIcon
      color="disabled" />,
    Cell:          ({ cell }) => <Box width="100%" textAlign="center">{cell.getValue()}</Box>,
  },
  {
    id:            'inviteSummary',
    header:        'Latest Invite',
    accessorFn:    ({ inviteStatus }) => inviteStatus,
    enableSorting: false,
    Cell:          ({ cell }) => <InviteSummaryCell inviteStatus={cell.getValue()} />,
  },
  {
    id:            'inviteUpdated',
    header:        'Invite Last Update',
    accessorFn:    ({ inviteStatus }) => inviteStatus?.lastUpdated ? new Date(inviteStatus?.lastUpdated) : null,
    enableSorting: false,
    Cell:          DateCell,
    muiTableHeadCellFilterTextFieldProps: {
      type: 'date',
    },
    sortingFn:                            'datetime',
    filterVariant:                        'range',

  },
];

export default function ListStudents () {

  const confirmDialogCtrl = useDialogCtrl();
  const snacks            = useSubmitSnack();


  const confirmBulkAction = (action, rowsSelected) => {
    const instr = bulkAction(action, rowsSelected);
    confirmDialogCtrl.openDialog({
      title:    instr.label,
      children: <Typography>{instr.description}</Typography>,
      actions:  (
                  <>
                    <Button variant={'contained'} autoFocus onClick={() => confirmDialogCtrl.onClose()}>Cancel</Button>
                    <Button variant={'outlined'} onClick={() => {
                      doBulkAction(action, rowsSelected);
                      confirmDialogCtrl.onClose()
                    }}>Confirm</Button>
                  </>
                )

    })
  }

  const doBulkAction = async (action, selectionModel) => {
    const instr = bulkAction(action, selectionModel);
    snacks.start(`Sending ${selectionModel.length} ${instr.noun}`);

    const proms   = selectionModel.map(s => instr.func(s));
    const results = await Promise.allSettled(proms);
    console.log(results)
    const stats = results.reduce((obj, res) => {
      if (res.status === 'rejected') {
        obj.failure++;
        console.error(res.reason);
      }
      if (res.status === 'fulfilled') {
        if (res.value.data.success) {
          obj.success++;
        } else {
          obj.incomplete++;
        }
      }
      return obj;
    }, {
      success:    0,
      failure:    0,
      incomplete: 0
    });

    snacks.success(`${stats.success} successfully send, ${stats.incomplete} did not send, ${stats.failure} failed to send.`);
  }


  return (
    <>

      {confirmDialogCtrl.open ? confirmDialogCtrl.getDialog() : null}


      <MaterialTable
        themeOverride
        columns={columns}
        query={GQL_QUERY_GET_STUDENTS}
        defaultState={{
          sort: [ { id: 'lastName', desc: false }, { id: 'firstName', desc: false } ],
        }}

        enableRowSelection

        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
          sx: {
            border:       '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 0,
          },
        }}

        renderTopToolbarCustomActions={
          ({
             table,
             ctrl: { resetDensity, resetColumnVisibility, resetPageSize, exportAllRows, rowSelection = {} },
           }) => {
            const rowSelectionIds = Object.getOwnPropertyNames(rowSelection);

            return (
              <Box sx={{ display: 'flex', p: '4px' }}>

                <MoreActionMenu
                  actions={[
                    {
                      text:    'Resend Invite Emails',
                      icon:    <RotateLeftIcon />,
                      onClick: () => confirmBulkAction('resend-invite', rowSelectionIds)
                    },
                    {
                      text:    'Create New Invites',
                      icon:    <SendIcon />,
                      onClick: () => confirmBulkAction('new-invite', rowSelectionIds)
                    },
                    {
                      text:    'Resend Confirmation Emails',
                      icon:    <RotateLeftIcon />,
                      onClick: () => confirmBulkAction('resend-confirm', rowSelectionIds)
                    },
                  ]}
                >
                  <Button
                    size={'small'}
                    color={'primary'}
                    startIcon={<MoreVertIcon color={rowSelectionIds.length ? 'primary' : 'disabled'} />}
                    disabled={rowSelectionIds.length === 0}
                  >
                    Bulk Actions
                  </Button>
                </MoreActionMenu>

                <MoreActionMenu
                  actions={[
                    {
                      icon:    <RestartAltIcon />,
                      text:    'Reset Density',
                      onClick: () => resetDensity(),
                    },
                    {
                      icon:    <RestartAltIcon />,
                      text:    'Reset Column Visibility',
                      onClick: () => resetColumnVisibility(),
                    },
                    {
                      icon:    <RestartAltIcon />,
                      text:    'Reset Page Size',
                      onClick: () => resetPageSize(),
                    },
                  ]}
                >
                  <Button
                    size={'small'}
                    color={'primary'}
                    startIcon={<MoreVertIcon color={'primary'} />}
                  >
                    More Options
                  </Button>
                </MoreActionMenu>

              </Box>
            )
          }}

        actionsProps={{
          header: '',
          size:   50
        }}
        rowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <IconButton
              color="primary"
              component={Link}
              href={`/students/${row.original.id}`}
            >
              <LinkIcon />
            </IconButton>
          </Box>
        )}

      />
    </>
  );
}


function GridActionsCellItem (
  {
    icon,
    href,
    label,
    ...rest
  }
) {

  let ttpIcon = (
    <Tooltip title={label}>
      {icon}
    </Tooltip>
  );

  return (
    <MuiGridActionsCellItem
      icon={ttpIcon}
      component={Link}
      href={href}
      label={label}
      {...rest}
    />
  );
}

function tooltipBooleanCol (
  {
    valueFormatter = (value) => value ? 'true' : 'false',
    trueIcon = <CheckIcon />,
    falseIcon = <CheckBoxOutlineBlankIcon />
  }
) {
  return function TooltipBooleanCol ({ value }) {
    return (
      <Tooltip title={valueFormatter(value)}>
        {value ? trueIcon : falseIcon}
      </Tooltip>
    );
  }
}


const bulkAction = (action, selections) => {
  let instructions = null;
  switch (action) {
    case 'new-invite':
      instructions = {
        func:        sendInvite,
        label:       'Send New Invitations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun:        'invitation email(s)'
      };
      break;
    case 'resend-invite':
      instructions = {
        func:        resendInviteEmail,
        label:       'Resend Latest Invitations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun:        'invitation email(s)'
      };
      break;
    case 'resend-confirm':
      instructions = {
        func:        resendConfirmEmail,
        label:       'Resend Confirmations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun:        'confirmation email(s)'
      };
      break;
    default:
      throw new Error('No action provided')
  }
  return instructions;
}



const sendInvite         = (id) => {
  return graphql({
    mutation:       GQL_MUTATION_CREATE_INVITE,
    variables:      { student: id, sendEmail: true },
    refetchQueries: [
      'getStudents'
    ]
  })
    .then(({ data }) => ({ data: { success: true } }))
}
const resendConfirmEmail = (id) => {
  return graphql({
    mutation:       GQL_MUTATION_RESEND_CONFIRM_EMAIL,
    variables:      { student: id },
    refetchQueries: [
      'getStudents'
    ]
  })
}
const resendInviteEmail  = (id) => {
  return graphql({
    query:          GQL_QUERY_RESEND_INVITE_EMAIL_BY_STUDENT,
    variables:      { student: id },
    refetchQueries: [
      'getStudents'
    ]
  })
}

function InviteSummaryCell ({ inviteStatus: { seen, visited, complete } = {} }) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
      <Tooltip title={seen ? 'Seen' : 'Not Seen'}>
        {seen ? <VisibilityIcon color="success" /> : <VisibilityOffIcon color="disabled" />}
      </Tooltip>
      <Tooltip title={visited ? 'Visited' : 'Not Visited'}>
        {visited ? <WebIcon color="success" /> : <WebAssetOffIcon color="disabled" />}
      </Tooltip>
      <Tooltip title={complete ? 'Has Timeslot' : 'Hasn\'t Selected Timeslot'}>
        {complete ? <DoneIcon color="success" /> : <PendingActionsIcon color="disabled" />}
      </Tooltip>
    </Box>
  );
}
