import { useQuery }                                                            from '@apollo/client';
import MaterialReactTable                                                      from 'material-react-table';
import { useEffect, useState }                                                 from 'react';
import { exportAllRows, gqlGetResponseMap, parseVariables as _parseVariables } from './queryFuncs';
import MaterialTableThemeOverride                                              from './themeOverride';
import useMaterialTableCtrl                                                    from './useTableCtrl';

export default function MaterialTable (
  {
    query,
    defaultState: _defaultState = {},
    queryResponseMap = gqlGetResponseMap,

    columns = [],

    parseVariables = _parseVariables,

    syncQueryParams = true,
    settingsKey,
    renderTopToolbarCustomActions: _renderTopToolbarCustomActions = (props) => null,
    rowActions,
    actionsProps = { header: '' },
    themeOverride = false,
    themeOverrideProps = {},

    enableRowSelection = false,

    ...other
  }
) {
  if (!settingsKey) {
    settingsKey = query.definitions[0].name.value;
    // console.log(settingsKey)
  }

  const defaultState = {
    page:   { pageIndex: 0, pageSize: 25 },
    sort:   [ { id: 'createdAt', desc: true } ],
    filter: [],
    text:   '',
    ..._defaultState,
  }


  const [ rowSelection, setRowSelection ] = useState({});

  const {
          getTableProps,
          page, sort, filter, text,
          resetDensity, resetColumnVisibility, resetPageSize
        } = useMaterialTableCtrl({
    page:         defaultState.page,
    sort:         defaultState.sort,
    filter:       defaultState.filter,
    text:         defaultState.text,
    settingsKey,
    syncQueryParams,
    columns,
    ignoreParams: _defaultState
  });


  const [ isRefetching, setIsRefetching ] = useState(false);

  const { loading: isLoading, error, data, refetch, previousData } = useQuery(query, {
    variables: parseVariables({ page, sort, filter, text }, { columns, defaultParseFunc: _parseVariables }),
  });

  const ctrl = {
    filter, sort, page, text,
    resetDensity, resetColumnVisibility, resetPageSize,
    isLoading, isRefetching, error,
    rowSelection,
    exportAllRows: () => exportAllRows({
      query,
      variables: parseVariables({ page, sort, filter, text }, { columns, defaultParseFunc: _parseVariables }),
      mapData:   queryResponseMap,
    })
  };

  useEffect(() => {
    setIsRefetching(true);
    refetch(parseVariables({ page, sort, filter, text }, { columns, defaultParseFunc: _parseVariables }))
      .finally(() => setIsRefetching(false));
  }, [ page.pageSize, page.pageIndex, sort, filter, text ]);

  useEffect(()=> {
    console.log('rowSelection', rowSelection)
  }, [ rowSelection ])

  const { items = [], meta = { count: 10000 } } = queryResponseMap(data ?? previousData ?? {});

  const renderTopToolbarCustomActions = ({ table, ...rest }) => {
    return _renderTopToolbarCustomActions({
      table,
      ...rest,
      ctrl
    });
  }

  const out = (
    <MaterialReactTable
      data={items}

      {...getTableProps({
        rowCount: meta.count,
        state:    {
          isLoading,
          isRefetching,
          showAlertBanner: !!error,
          rowSelection: rowSelection,
        }
      })}

      enableRowSelection={enableRowSelection}
      onRowSelectionChange={setRowSelection}
      getRowId={(row) => row.id}
      renderTopToolbarCustomActions={renderTopToolbarCustomActions}

      muiToolbarAlertBannerProps={
        error
        ? {
            color:    'error',
            children: 'Error loading data',
          }
        : undefined
      }

      displayColumnDefOptions={{
        ...(rowActions && actionsProps ? { 'mrt-row-actions': actionsProps } : {}),
      }}
      enableRowActions={!!rowActions}
      renderRowActions={(props) => rowActions?.({ ...props, ctrl }) ?? null}

      {...other}
    />
  );

  return themeOverride
         ? <MaterialTableThemeOverride {...themeOverrideProps}>{out}</MaterialTableThemeOverride>
         : out
    ;
}


