import formatDate          from 'date-fns/format';
import { graphql }         from '../../data/client';
import { parseBadUtcDate } from './useTableCtrl';

export function exportAllRows (
  {
    query,
    variables,
    mapData = (data) => data
  }
) {
  return graphql({
    query:     query,
    variables: {
      ...variables,
      page: { limit: 0 }
    }
  })
    .then(({ data }) => data)
    .then(mapData)
    ;
}

export function gqlGetResponseMap (data) {
  if (!data) {
    return data;
  }

  const keys = Object.getOwnPropertyNames(data);
  return keys.length ? data[keys[0]] : data;
}

export function parseVariables (
  { page = {}, sort = [], filter = {}, text = '' },
  { columns = [], defaultParseFunc } = {}
) {

  const f = [
    ...filter,
    ...(text ? [ { id: 'text', value: text } ] : [])
  ]
    .reduce((acc, { id, value }) => {
      const c   = columns.find(c => c.id === id);
      const key = c?.filterKey ?? id;


      const tmp = valueToFilter(c?.filterParser ? c.filterParser(value) : value);

      if (c?.filterVariant === 'range') {
        if (tmp[0]) {
          set(acc, `${key}_gte`, tmp[0]);
        }
        if (tmp[1]) {
          set(acc, `${key}_lte`, tmp[1]);
        }
      } else {
        set(acc, key, tmp);
      }

      return acc;
    }, {});
  // console.log({ f })

  const s = !!text ? [] : sort.map(({ id, desc }) => ({
      field:     columns.find(c => c.id === id)?.sortKey ?? id,
      direction: desc ? -1 : 1
    }))
  ;


  return {
    filter: f,
    sort:   s,
    page:   {
      page:  (page.pageIndex ?? 0) + 1,
      limit: page.pageSize ?? 25,
    }
  }
}



function valueToFilter (value) {
  const convert = (v) => {
    v = v === undefined ? '' : v
    if (v instanceof Date) {
      return parseBadUtcDate(v);
    }
    return v;
  }

  return Array.isArray(value) ? value.map(convert) : convert(value);
}

/**
 * Set a value at a path in an object
 *
 * @param {Object} obj
 * @param {String} path
 * @param {*} value
 */
function set (obj, path, value) {
  let schema  = obj;  // a moving reference to internal objects within obj
  const pList = path.split('.');
  const len   = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {}
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}


export function exportByColumn(data, columns) {
  const headers = columns.map(({ header }) => header);
  return [
    headers,
    ...(data?.items ?? []).map(p => [
      ...columns.map((c) => {
        let value = '';
        if (c.accessorKey) {
          value = get(p, c.accessorKey);
        } else if (c.accessorFn) {
          value = c.accessorFn(p);
        }

        if (isDate(value)) {
          value = formatDate(value, 'MM/dd/yyyy');
        }
        if (c.id === 'isSubcontract') {
          value = value ? 'Yes' : 'No';
        }

        return value;
      })
    ])
  ];
}


function get (obj, prop) {
  const [ left, right ] = prop.split('.', 1);
  return right ? get((obj?.[left] ?? {}), right) : (obj?.[left] ?? undefined);

}
function isDate(d) {
  return typeof d === 'object' && d instanceof Date;
}
