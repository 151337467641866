import Box                    from '@mui/material/Box';
import formatDate             from 'date-fns/format';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLocalSetting }    from '../useLocalSettings';

const intParser = (v) => v?.replace ? parseInt(v.replace(/[^0-9]/g, '')) : Number(v);

export const projectColumns = [
  {
    id:           'krProposalNum',
    header:       'Proposal #',
    filterKey:    'proposalNum',
    filterParser: intParser,
    accessorKey:  'krProposalNum',
  },
  {
    id:           'legacyId',
    header:       'Legacy ID',
    filterParser: intParser,
    accessorKey:  'legacyId',
  },
  {
    id:            'piName',
    header:        'Investigator',
    enableSorting: false,
    accessorFn:    ({ data }) => data.pi ? `${data.pi.lastName}, ${data.pi.firstName}` : '<unasigned>',
  },
  {
    id:            'piUcinetid',
    header:        'UCINetID',
    enableSorting: false,
    hidden:        true,
    accessorFn:    ({ data }) => data.pi?.ucinetid ? data.pi.ucinetid : '--',
  },
  {
    id:            'agencyName',
    header:        'Agency',
    enableSorting: false,
    accessorFn:    ({ data }) => data?.agency?.acronym || data?.agency?.name || '<unasigned>',
  },
  {
    id:            'agencyType',
    header:        'Agency Type',
    enableSorting: false,
    hidden:        true,
    accessorFn:    ({ data }) => data.agency?.type ? data.agency.type : '--',
  },
  {
    id:         'isSubcontract',
    header:     'UCI Is Subcon.',
    sortKey:       'data.primeAgencyId',
    // enableSorting: false,
    accessorFn: ({ data, subcontract }) => data?.primeAgencyId ? <CheckCircleOutlineIcon color='primary' /> : '',
    Cell:      ({ cell }) => <Box width='100%' textAlign='center'>{cell.getValue()}</Box>,
  },
  {
    id:          'subconInst',
    header:      'Prime Institution',
    hidden:      true,
    // sortKey:     'data.primeAgency',
    accessorKey: 'data.primeAgency.name',
  },
  {
    id:          'subconPi',
    header:      'Prime PI',
    hidden:      true,
    sortKey:     'subcontract.pi',
    accessorKey: 'subcontract.pi',
  },
  {
    id:          'subconAward',
    header:      'Prime Award #',
    hidden:      true,
    sortKey:     'subcontract.primeAwardNum',
    accessorKey: 'subcontract.primeAwardNum',
  },
  {
    id:         'title',
    header:     'Title',
    hidden:     true,
    sortKey:    'data.title',
    accessorFn: ({ data }) => data?.title ?? '',
  },
  {
    id:         'desc',
    header:     'Description',
    hidden:     true,
    sortKey:    'description',
    accessorFn: ({ description }) => description ?? '',
  },
  {
    id:         'type',
    header:     'Type',
    hidden:     true,
    accessorFn: ({ data }) => data?.type ?? '',
  },
  {
    id:         'nihActivityCode',
    header:     'NIH Activity Code',
    hidden:     true,
    sortKey:    'data.nihActivityCode',
    accessorKey: 'data.nihActivityCode',
  },
  {
    id:                  'status',
    header:              'Status',
    accessorFn:          ({ data }) => data.status,
    filterKey:           'status_in',
    sortKey:             'data.status',
    filterVariant:       'multi-select',
    filterSelectOptions: [
      'Awarded',
      'Completed',
      'Deactivated',
      'Denied',
      'Funded',
      'Pending',
      'Processed',
      'Rejected',
    ],

  },
  {
    id:         'purpose',
    header:     'Purpose',
    hidden:     true,
    sortKey:    'data.purpose',
    accessorFn: ({ data }) => data?.purpose ?? '',
  },
  {
    id:         'leadUnit',
    header:     'Lead Unit',
    hidden:     true,
    sortKey:    'data.uciCreditDept',
    accessorFn: ({ data }) => data?.uciCreditDept ?? '',
  },
  {
    id:         'uciFundNum',
    header:     'UCI Fund #',
    hidden:     true,
    sortKey:    'data.uciFundNum',
    accessorFn: ({ data }) => data?.uciFundNum ?? '',
  },
  {
    id:         'agencyAwardNum',
    header:     'Agency Award #',
    sortKey:    'data.agencyAwardNum',
    hidden:     true,
    accessorFn: ({ data }) => data?.agencyAwardNum ?? '',
  },
  {
    id:                                   'proposal',
    header:                               'Date Submitted',
    sortKey:                              'data.proposal',
    filterVariant:                        'range',
    type:                                 'date',
    Cell:                                 DateCell, //transform data to readable format for cell render
    accessorFn:                           ({ data }) => new Date(data?.proposal), //transform data before processing so sorting works
    muiTableHeadCellFilterTextFieldProps: {
      type: 'date',
    },
    sortingFn:                            'datetime',
  },
  {
    id:                                   'start',
    header:                               'Start',
    sortKey:                              'data.start',
    type:                                 'date',
    Cell:                                 DateCell, //transform data to readable format for cell render
    accessorFn:                           ({ data }) => new Date(data?.start), //transform data before processing so sorting works
    muiTableHeadCellFilterTextFieldProps: {
      type: 'date',
    },
    sortingFn:                            'datetime',
  },
  {
    id:                                   'end',
    header:                               'End',
    sortKey:                              'data.end',
    type:                                 'date',
    Cell:                                 DateCell, //transform data to readable format for cell render
    accessorFn:                           ({ data }) => new Date(data?.end), //transform data before processing so sorting works
    muiTableHeadCellFilterTextFieldProps: {
      type: 'date',
    },
    sortingFn:                            'datetime',
  },
  {
    id:         'createdAt',
    header:     'Creation Date',
    hidden:     true,
    Cell:       DateCell, //transform data to readable format for cell render
    accessorFn: ({ createdAt }) => new Date(createdAt),
  },
  {
    id:         'updatedAt',
    header:     'Update Date',
    hidden:     true,
    Cell:       DateCell, //transform data to readable format for cell render
    accessorFn: ({ updatedAt }) => new Date(updatedAt),

  },
];

export function DateCell ({ cell, format = 'MM/dd/yyyy' }) {
  const [ debugDate ] = useLocalSetting('debugDates');
  const [ debugDateString ] = useLocalSetting('debugDateString');
  const v = cell.getValue();

  return v ? formatDate(v, debugDate ? debugDateString : format) : null;
}

