import { createContext, useContext } from 'react';
import { useLocalStorage }           from '../utils/localstore'

const LOCALSTORAGE_KEY_FIELD = 'local-settings';

const defSettings          = {
  // dashboardDrawerOpen: false,
  // projectNavOpen:      true,
  // debugDates:          false,
  // debugDateString:     'yyyy-MM-dd HH:mm:ss XX',
};
const defaultFuncs         = {
  getSetter:    () => {
  },
  setSetting:   () => {
  },
  unsetSetting: () => {
  },
  getUnsetter:  () => {
  },
}
const localSettingsContext = createContext({ ...defSettings, ...defaultFuncs });

// const { get, set, reset } = makeStore(LOCALSTORAGE_TOKEN_FIELD);

export function LocalSettingsContext ({ children }) {
  const [ state, setState ] = useLocalStorage(LOCALSTORAGE_KEY_FIELD, defSettings);

  const setSetting   = (key, value) => {
    const valueToStore =
            value instanceof Function ? value(state[key]) : value;

    // console.log({ key, value, valueToStore, oldValue: state[key] })
    setState(s => ({ ...s, [key]: valueToStore }));
  };
  const unsetSetting = (key) => {
    setState(s => {
      const { [key]: _, ...rest } = s;
      return rest;
    })
  };
  const getSetter    = (key) => setSetting.bind(null, key);
  const getUnsetter  = (key) => unsetSetting.bind(null, key);

  return <localSettingsContext.Provider value={{ ...state, setSetting, getSetter, getUnsetter }} children={children} />
}

// export function useLocalSettings (...settingsLabels) {
//   const { getSetter, setSetting, ...state } = useContext(localSettingsContext);
//
//   const out = useMemo(() => {
//     return settingsLabels.reduce((obj, key) => {
//       obj[key]                  = state[key];
//       obj[`set${ucFirst(key)}`] = getSetter(key);
//
//       return obj;
//     }, {});
//   }, [ settingsLabels, state, getSetter ]);
//
//
//   return out;
// }

export function useLocalSetting (key, initialValue) {
  const { getSetter, setSetting, getUnsetter, ...state } = useContext(localSettingsContext);

  if (typeof state[key] === 'undefined' && typeof initialValue === 'undefined') {
    console.warn(`No main default for setting ${key}. Admin should add default in useLocalSettings.js`);
  } else if (typeof state[key] === 'undefined' && typeof initialValue !== 'undefined') {
    setSetting(key, initialValue);
  }
  const setter   = getSetter(key);
  const unsetter = getUnsetter(key);

  return [ state[key] ?? initialValue, setter, unsetter ];
}

// function shallowClone (value) {
//   if (Array.isArray(value)) {
//     return [ ...value ];
//   } else if (typeof value === 'object') {
//     return { ...value };
//   } else {
//     return value;
//   }
// }
