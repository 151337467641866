import { ThemeProvider, useTheme } from '@mui/material';
import { createTheme }             from '@mui/material/styles';
import { useMemo }     from 'react';

export default function MaterialTableThemeOverride ({ children, inherit = true, ...props }) {
  const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () => {
      let tmp = {};
      if (inherit) {
        tmp = {
          palette:    { ...globalTheme.palette },
          components: { ...globalTheme.components }
        };
      }
      tmp.palette.background.default = '#fff';

      return createTheme(tmp);
    },
    [ globalTheme ],
  );

  return (
    <ThemeProvider theme={tableTheme} children={children} {...props} />
  )
}
