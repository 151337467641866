import formatDate              from 'date-fns/format';
import parseDate               from 'date-fns/parse';
import { useEffect, useState } from 'react';
import { useSearchParams }     from 'react-router-dom';
import { useLocalSetting }     from '../useLocalSettings';



export default function useMaterialTableCtrl (
  {
    columns = [],
    page:   _page = {},
    sort:   _sort = [],
    filter: _filter = [],
    text:   _text = '',
    settingsKey = '',
    syncQueryParams = true,
    ignoreParams = {}
  }
) {

  const [ _searchParams, setSearchParams ] = useSearchParams();
  const searchParams                       = searchParamsToState(parseForArrays(_searchParams), columns);

  const defaultColumnVisibility                   = columns.reduce((acc, { id, hidden = false }) => ({
    ...acc,
    [id]: !hidden
  }), {})
  const [ columnVisibility, setColumnVisibility ] = useLocalSetting(`${settingsKey}:columnVisibility`, defaultColumnVisibility);
  const [ density, setDensity ]                   = useLocalSetting(`${settingsKey}:density`, 'comfortable');
  const [ pageSize, setPageSize ]                 = useLocalSetting(`${settingsKey}:pageSize`, _page.pageSize ?? 25);


  const [ page, setPage ]     = useState(() => ({
    pageIndex: searchParams?.page?.pageIndex ?? _page?.pageIndex ?? 0,
    pageSize: searchParams?.page?.pageSize ?? pageSize
  }));
  const [ sort, setSort ]     = useState(searchParams.sort?.length ? searchParams.sort : _sort);
  const [ filter, setFilter ] = useState(filterDuplicates([ ...searchParams.filter, ..._filter ]));
  const [ text, setText ]     = useState(searchParams.text || _text);

  useEffect(() => {
    // let args = removeIgnores({ page, sort, filter, text }, ignoreParams)

    setSearchParams(
      searchStateToParams({ page, sort, filter, text }, { page: _page, sort: _sort, filter: _filter, text: _text }),
      { replace: true }
    );
    if (page.pageSize && page.pageSize !== pageSize) {
      setPageSize(page.pageSize);
    }
  }, [ page.pageSize, page.pageIndex, sort, filter, text ]);


  const getTableProps = (data) => {
    const {
            state,
            initialState,

            ...rest
          } = data;

    return {
      columns,

      getRowId:         (row) => row.id,
      manualFiltering:  true,
      manualPagination: true,
      manualSorting:    true,

      onPaginationChange:    setPage,
      onColumnFiltersChange: setFilter,
      onGlobalFilterChange:  setText,
      onSortingChange:       setSort,

      onColumnVisibilityChange: setColumnVisibility,
      onDensityChange:          setDensity,

      state: {
        pagination:    page,
        columnFilters: filter,
        globalFilter:  text,
        sorting:       text ? [] : sort,
        density:       density,
        columnVisibility,

        ...state,
      },

      initialState: {
        ...( text ? {showGlobalFilter: true} : {} ),
        ...initialState,
      },


      ...rest
    }

  }

  const resetColumnVisibility = () => {
    setColumnVisibility(() => ({ ...defaultColumnVisibility }));
  }
  const resetDensity          = () => {
    setDensity('comfortable');
  }
  const resetPageSize          = () => {
    setPage((page) => ({ ...page, pageSize: _page?.pageSize ?? 25 }));
    setPageSize(_page?.pageSize ?? 25);
  }


  return {
    getTableProps,

    page,
    setPage,

    sort,
    setSort,

    filter,
    setFilter,

    text,
    setText,

    columnVisibility,
    setColumnVisibility,
    resetColumnVisibility,

    density,
    setDensity,
    resetDensity,

    pageSize,
    setPageSize,
    resetPageSize,

  };
}



function parseForArrays (urlSearchParams) {
  // console.log('parseForArray', Array.from(urlSearchParams.entries()))
  let out = {};

  for (let [ k, v ] of urlSearchParams) {
    // console.log(k, v)
    if (/\[]$/.test(k)) {
      k = k.replace(/\[]$/, '');
      if (!out[k]) {
        out[k] = [];
      }
      out[k].push(v);
    } else {
      out[k] = v;
    }
  }

  return out;
}

function searchStateToParams (
  { page = {}, sort = [], filter = [], text = '' } = {},
  defaults                                         = {},
) {
  // console.log(filter)
  const tmpFilter = filter.reduce((acc, { id, value }) => {
    const f            = defaults?.filter?.find(f => f.id === id);
    const isEmptyArray = Array.isArray(value) && !value.filter(v => !!v).length;

    if (!isEmptyArray && valueIsDifferent(f?.value, value)) {
      // console.log(1, id, value)
      if (Array.isArray(value)) {
        id += '[]';
      }
      acc[id] = valueToStringable(value);
    }
    return acc;
  }, {});
  // console.log({ filter, tmpFilter })

  const out = {
    ...objectRemoveSame(page, defaults.page),
    ...tmpFilter,
    ...(text ? { text } : {})
  };

  // console.log(sort, defaults.sort);

  if (sort?.length && !arraySameOrderAndValue(sort, defaults.sort)) {
    out.sort = sort
      .filter(({ desc }) => desc !== null)
      .map(({ id, desc }) => `${desc ? '-' : ''}${id}`)
      .join(',')
    ;
  } else if (!(sort?.length) && defaults.sort?.length) {
    out.sort = defaults.sort
      .map(({ id }) => `~${id}`)
      .join(',')
    ;
  }

  return out;

}

function objectRemoveSame (from = {}, compare = {}) {
  const out = {};
  for (let k in compare) {
    if (from[k] !== compare[k]) {
      out[k] = from[k];
    }
  }

  return out;
}

function searchParamsToState ({ pageIndex, pageSize, sort: _sort, text, ...rest } = {}, columns = []) {

  const page = {
    ...(pageIndex ? { pageIndex: +pageIndex } : {}),
    ...(pageSize ? { pageSize: +pageSize } : {}),
  }

  const sort = (_sort ?? '')
    .split(',')
    .filter(s => s)
    // .filter(s => !s.startsWith('~'))
    .map((s) => ({
      id:   s.replace(/^-/, ''),
      desc: s.startsWith('~') ? null : s.startsWith('-')
    }))
  ;

  const filter = Object.getOwnPropertyNames(rest).map(id => {
    // const c = columns.find(c => c.id === id);
    return { id, value: stringableToValue(rest[id]) }
  });
  // console.log({ filter })

  return {
    page,
    sort,
    text,
    filter
  };
}

function filterDuplicates (arr) {
  return arr.filter(({ id }, i, a) => a.findLastIndex(v => v.id === id) === i);
}

function arraySameOrderAndValue (arr1, arr2) {
  for (let i in arr1) {
    if (arr1[i].id !== arr2[i].id || arr1[i].desc !== arr2[i].desc) {
      return false;
    }
  }
  return true;
}

function valueToStringable (value) {
  const convert = (v) => {
    // console.log(v);
    v = v === undefined ? '' : v
    if (v instanceof Date) {
      return formatDate(parseBadUtcDate(v), 'yyyy-MM-dd');
    }
    return v;
  }

  return Array.isArray(value) ? value.map(convert) : convert(value);
}

function stringableToValue (string) {
  const convert = (s) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(s)) {
      if (s) {
        return parseDate(s + ' Z', 'yyyy-MM-dd X', new Date());
      }
      return undefined;
    }
    return s;
  }

  return Array.isArray(string) ? string.map(convert) : convert(string);
}

function valueIsDifferent (a, b) {
  return JSON.stringify(a) !== JSON.stringify(b);
}

export function parseBadUtcDate (date) {
  const tmp = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
  return parseDate(tmp, 'yyyy-M-d', new Date());
}
