import { gql }              from '@apollo/client';
import { client as apollo } from '../../Context/Apollo';
// import { client as apollo } from '../../Context/Apollo';

export const GQL_QUERY_GET_STUDENTS = gql`
  query getStudents($filter: StudentFilterInput, $sort: [ SortInput ], $page: PaginationInput) {
    listStudents(filter: $filter, sort: $sort, page: $page) {
      items {
        id
        uciStudentNum
        firstName
        lastName
        email
        phone
        program
        subject
        timeslot {
          id day startTime endTime
        }
        inviteStatus {
          seen
          visited
          complete
          lastCreated
          lastUpdated
        }
      }
      meta {
        count pages
      }
    }
  }
`;
export const GQL_QUERY_GET_STUDENT  = gql`
  query getStudent($id: ID!) {
    getStudent(id: $id) {
      id

      uciStudentNum
      firstName
      lastName
      email
      program
      subject
      phone

      timeslotId
      timeslot {
        id
        day
        startTime
        endTime
      }

      #      inviteOpened 
      #      inviteSent

      invites {
        id

        key
        emails {
          id
          messageId
          createdAt
        }
        seen
        visited
        complete

        updatedAt
        createdAt
      }

      emails {
        id
        type
        to
        inviteId
        createdAt
      }
    }
  }
`;

export const GQL_MUTATION_EDIT_STUDENT = gql`
  mutation editStudent($input: StudentInput!, $id: ID!) {
    student: editStudent(id: $id, input: $input) {
      id
      urlId
    }
  }
`;

export function editStudentMutation ({ student, id }) {
  return apollo.mutate({
    mutation:  GQL_MUTATION_EDIT_STUDENT,
    variables: { input: student, id }
  })
}

export const GQL_MUTATION_NEW_STUDENT = gql`
  mutation addStudent($input: CreateStudentInput!) {
    student: createStudent(input: $input) {
      id
      lastName
      firstName
    }
  }
`;

export function makeStudentMutation ({ student }) {
  return apollo.mutate({
    mutation:  GQL_MUTATION_NEW_STUDENT,
    variables: { input: student }
  })
}

export const GQL_MUTATION_DELETE_STUDENT = gql`
  mutation addStudent($id: ID!) {
    removeStudent(id: $id) {
      id email firstName lastName
    }
  }
`;

export function deleteStudentMutation ({ student }) {
  return apollo.mutate({
    mutation:  GQL_MUTATION_DELETE_STUDENT,
    variables: { id: student }
  })
}

export const GQL_QUERY_FIND_STUDENT_BY_EMAIL = gql`
  query findStudentByEmail($email: String!) {
    students(page: {limit: 1}, filter: {search: $email}) {
      id
    }
  }
`;

export function findStudentByEmail ({ email }) {
  return apollo.query({
    query:     GQL_QUERY_FIND_STUDENT_BY_EMAIL,
    variables: { email }
  })
}
