import { client as apollo } from '../Context/Apollo';

export function graphql ({mutation, query, variables = {}, refetchQueries = [], ...options } = {}) {
  if (mutation) {
    if (!refetchQueries.length) {
      console.warn(`No refetchQueries provided for mutation: ${mutation}`);
    } else {
      console.log(`Refetching queries: ${refetchQueries}`);
    }

    return apollo.mutate({
      ...options, mutation, variables, refetchQueries
    });
  }
  if (query) {
    return apollo.query({
      ...options, query, variables, refetchQueries
    });
  }
  throw new Error(`No query or mutation provided. Please provide either.`);
}
